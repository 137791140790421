<script>
import { MaskedRange } from 'imask'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { computed } from 'vue'
import { useRouter } from 'vue-router/composables'
import emitter from '/~/core/emitter'
import modal from '/~/core/mdl'
import BaseBack from '/~/components/base/back/base-back.vue'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseCheckbox from '/~/components/base/checkbox/base-checkbox.vue'
import BaseInput from '/~/components/base/input/base-input.vue'
import BaseLink from '/~/components/base/link/base-link.vue'
import BaseMetafield from '/~/components/base/metafield/base-metafield.vue'
import BaseRadio from '/~/components/base/radio/base-radio.vue'
import { useAddressFinder } from '/~/composables/addresses'
import { useAuth } from '/~/composables/auth'
import { useForm } from '/~/composables/base/use-form'
import { useCms } from '/~/composables/cms/use-cms'
import { useLocalization } from '/~/composables/localization'
import { useLogger } from '/~/composables/logger'
import { useProvider } from '/~/composables/provider'
import { useUser } from '/~/composables/user'

const logger = useLogger()

export default {
  name: 'auth-register-form',
  components: {
    BaseInput,
    BaseButton,
    BaseBack,
    BaseCheckbox,
    BaseLink,
    BaseRadio,
    BaseMetafield,
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    query: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    const { isAddressFinderSearching } = useAddressFinder()
    const {
      isRegistrationAttempts,
      isBillPaymentsTemplate,
      isSymbioneliteProvider,
      isClaimsEnabled,
      registrationForm,
      userMetadata,
      userMetadataSchema,
    } = useProvider()
    const { signUp } = useAuth()
    const { fetchUserClaimStatus, fetchUserDetails, setUser } = useUser()
    const { providerAccountDetailsDescription } = useCms()
    const router = useRouter()
    const localization = useLocalization()
    const { translate } = useLocalization()
    const { registerPageTitle } = useCms()
    const { validationObserverRef } = useForm()

    const businessNumberMask = computed(() =>
      localization.getMask('businessNumber')
    )

    return {
      isAddressFinderSearching,
      isRegistrationAttempts,
      signUp,
      isBillPaymentsTemplate,
      isSymbioneliteProvider,
      isClaimsEnabled,
      registrationForm,
      userMetadata,
      userMetadataSchema,
      fetchUserClaimStatus,
      fetchUserDetails,
      providerAccountDetailsDescription,
      setUser,
      modal,
      router,
      localization,
      businessNumberMask,
      registerPageTitle,
      validationObserverRef,
      translate,
    }
  },
  data() {
    return {
      form: {
        first_name: this.query?.first_name || '',
        last_name: this.query?.last_name || '',
        email: this.query?.email || '',
        confirm_email: '',
        mobile: this.query?.mobile || '',
        abn: this.query?.abn?.replaceAll(/[^\d]/g, '') || '',
        postcode: this.query?.postcode || '',
        username: this.query?.username || '',
        password: '',
        terms: false,
        userMetadata: {},
        ...JSON.parse(this.$route.query.form ?? '{}'),
      },
      processing: false,
      backendErrors: {},
      dateMask: {
        mask: this.localization.getDatePickerFormat('daymonthyearnumeric'),
        lazy: true,
        blocks: {
          YYYY: {
            mask: MaskedRange,
            from: 1940,
            to: 2020,
          },
          MM: {
            mask: MaskedRange,
            from: 1,
            to: 12,
          },
          DD: {
            mask: MaskedRange,
            from: 1,
            to: 31,
          },
        },
        min: new Date(1940, 0, 1),
        max: new Date(2020, 0, 1),
      },
    }
  },
  computed: {
    disabled() {
      return this.processing
    },
    requirements() {
      return this.registrationForm ?? {}
    },
    hasAdditionalFields() {
      return Object.keys(this.requirements).some(
        (key) => key !== 'terms' && this.requirements[key].enabled
      )
    },
    hasErrors() {
      return Boolean(this.validationObserverRef?.flags.invalid)
    },
    isUnfilled() {
      return Object.entries(this.form).some(([key, value]) => {
        return !value && value !== 0 && this.fieldRequirements(key).enabled
      })
    },
    businessNumberEnabled() {
      return this.fieldRequirements('abn').enabled
    },
    businessNumberPending() {
      return this.validationObserverRef?.refs.abn?.flags.pending
    },
    businessNumberValid() {
      const { valid, pending } =
        this.validationObserverRef?.refs.abn?.flags ?? {}

      return valid && !pending
    },
    submitDisabled() {
      return (
        this.isAddressFinderSearching ||
        this.isUnfilled ||
        this.hasErrors ||
        this.disabled ||
        (this.businessNumberEnabled && !this.businessNumberValid)
      )
    },
    fieldRequirements() {
      return (name) => ({
        enabled: false,
        fieldname: null,
        ...this.requirements[name],
      })
    },
    usernameFieldLabel() {
      const { fieldname } = this.fieldRequirements('username')

      if (fieldname) {
        return fieldname
      }

      return 'Username'
    },
    isPasswordHidden() {
      return this.requirements.password?.hidePassword ?? false
    },
    isConfirmEmailEnabled() {
      return this.requirements.email?.confirmEmail ?? false
    },
    userMetadataValues() {
      return Object.entries(this.form.userMetadata).map(([key, value]) => {
        return {
          key,
          value,
        }
      })
    },
  },
  mounted() {
    if (Object.keys(this.query).length > 0) {
      this.validationObserverRef.validate({ silent: true })
    }
  },
  methods: {
    getPhone(evt) {
      if (evt.length > 12) {
        this.form.mobile = evt.slice(0, -1)
      }
    },
    removeWhiteSpace(text) {
      return text.replace(/[\s/]/g, '')
    },
    async submit() {
      this.processing = true
      this.backendErrors = {}

      const { token } = this.$route.query
      const payload = { ...this.form }

      if (this.isPasswordHidden) {
        delete payload.password
      }

      try {
        const user = await this.signUp(token, payload)

        await this.handlePostSignUp(user)
      } catch (error) {
        this.handleSignUpError(error)
      } finally {
        this.processing = false
      }
    },
    // fetch user details after successful registration when 2fa is disabled
    async handlePostSignUp(user) {
      try {
        if (this.isClaimsEnabled) {
          await this.fetchUserClaimStatus()
        }

        if (this.isPasswordHidden && this.isBillPaymentsTemplate) {
          this.router.push({ name: 'auth-register-success' })
        } else if (user) {
          const userData = await this.fetchUserDetails()

          emitter.emit('auth:success', userData)
        }
      } catch (error) {
        logger.error('Error fetching user details', error)
      }
    },
    handleSignUpError(error) {
      this.backendErrors = {}

      if (error?.status === 403) {
        logger.debug('Registration forbidden')
        this.router.push({ name: 'auth-register-failed' })
        return
      }

      if (error) {
        Object.keys(error).forEach((key) => {
          if (Array.isArray(error[key])) {
            this.backendErrors[key] = error[key][0] || ''
          }
        })

        this.$notify({
          text: 'There are errors with the information entered. Please review and make changes to proceed.',
          type: 'error',
          duration: 5000,
        })
      }
    },
    showAccountDetails() {
      modal.show('account-details-info')
    },
    generateAnalyticsLabel(e) {
      switch (e?.field?.type) {
        case 'bool':
          return `${e?.field?.label} ${e?.value ? 'Tick' : 'Untick'}`
        case 'radio':
          return `${e?.field?.label} set to ${e?.value}`
        default:
          return `${e?.field?.label} field changed`
      }
    },
  },
}
</script>

<template>
  <validation-observer
    ref="validationObserverRef"
    v-slot="{ invalid }"
    v-analytics:submit="{ pageGroup: 'Auth', page: 'Register', cta: 'Next' }"
    tag="form"
    class="mx-auto w-full max-w-screen-xs p-4"
    @submit.prevent="submit"
  >
    <h1
      class="mb-4 text-xl font-bold text-eonx-neutral-800 sm:mb-10 sm:text-left sm:text-2xl md:mt-[60px]"
    >
      {{ registerPageTitle }}
    </h1>
    <div v-if="providerAccountDetailsDescription" class="mb-5 text-left">
      <span>
        {{ providerAccountDetailsDescription }}
      </span>
      <base-button
        v-analytics:click="{
          pageGroup: 'Auth',
          page: 'Register',
          cta: 'Locate account details',
        }"
        look="link"
        @click="showAccountDetails"
      >
        Click here
      </base-button>
    </div>
    <div class="grid gap-x-4 sm:grid-cols-2">
      <base-input
        v-if="fieldRequirements('first_name').enabled"
        v-model="form.first_name"
        v-analytics:input="{
          pageGroup: 'Auth',
          page: 'Register',
          label: 'First Name field changed',
        }"
        :validation="{
          rules: 'required',
          name: 'First Name',
        }"
        :disabled="disabled"
        required
        :error="backendErrors['first_name']"
        name="first_name"
        label="First Name"
      />
      <base-input
        v-if="fieldRequirements('last_name').enabled"
        v-model="form.last_name"
        v-analytics:input="{
          pageGroup: 'Auth',
          page: 'Register',
          label: 'Last Name field changed',
        }"
        :validation="{
          rules: 'required',
          name: 'Last Name',
        }"
        :disabled="disabled"
        required
        :error="backendErrors['last_name']"
        label="Last Name"
        name="last_name"
      />
      <base-input
        ref="email"
        v-model="form.email"
        v-analytics:input="{
          pageGroup: 'Auth',
          page: 'Register',
          label: 'Email field changed',
        }"
        :validation="{
          rules: 'required|email',
          name: 'Email',
          vid: 'email',
        }"
        :disabled="disabled"
        :required="true"
        :error="backendErrors['email']"
        label="Email"
        type="email"
        name="email"
      />
      <base-input
        v-if="isConfirmEmailEnabled"
        v-model="form.confirm_email"
        v-analytics:input="{
          pageGroup: 'Auth',
          page: 'Register',
          label: 'Confirm Email field changed',
        }"
        :validation="{
          rules: 'required|email|confirmed:@email,Email',
          name: 'Confirm Email',
          vid: 'confirm_email',
        }"
        :disabled="disabled"
        :required="true"
        :error="backendErrors['confirm_email']"
        label="Confirm Email"
        type="email"
        name="confirm_email"
      />
      <base-input
        v-if="!isPasswordHidden"
        v-model="form.password"
        v-analytics:input="{
          pageGroup: 'Auth',
          page: 'Register',
          label: 'Password field changed',
        }"
        :validation="{
          rules: 'required|password',
          name: 'Password',
        }"
        :disabled="disabled"
        :required="true"
        :error="backendErrors['password']"
        label="Password"
        type="password"
        name="password"
        autocomplete="new-password"
      />
      <base-input
        v-if="fieldRequirements('mobile').enabled"
        v-model="form.mobile"
        v-analytics:input="{
          pageGroup: 'Auth',
          page: 'Register',
          label: 'Mobile field changed',
        }"
        :validation="{
          rules: 'required|mobile',
          name: 'Mobile',
        }"
        :disabled="disabled"
        :required="true"
        :error="backendErrors['mobile']"
        mask="mobile"
        type="tel"
        label="Mobile"
        name="mobile"
      />
      <base-input
        v-if="fieldRequirements('username').enabled"
        v-model="form.username"
        :validation="{
          rules: 'required',
          name: fieldRequirements('username').fieldname,
        }"
        :disabled="disabled"
        :label="usernameFieldLabel"
        :required="true"
        rules="required"
        :error="backendErrors['uan'] || backendErrors['username']"
        name="uan"
      />
      <base-input
        v-if="fieldRequirements('birthday_date').enabled"
        v-model="form.birthday_date"
        :disabled="disabled"
        :label="fieldRequirements('birthday_date').fieldname || 'Date of Birth'"
        :mask="dateMask"
        :placeholder="localization.getDatePickerFormat('daymonthyearnumeric')"
      />
      <div v-if="fieldRequirements('gender').enabled">
        <div class="mb-[5px] text-left leading-5">
          {{ fieldRequirements('gender').fieldname || 'Gender' }}
        </div>
        <div class="flex">
          <base-radio
            v-model="form.gender"
            :disabled="disabled"
            value="1"
            name="gender"
            class="mr-[15px]"
          >
            Male
          </base-radio>
          <base-radio
            v-model="form.gender"
            :disabled="disabled"
            value="2"
            name="gender"
          >
            Female
          </base-radio>
        </div>
      </div>

      <base-input
        v-if="fieldRequirements('postcode').enabled"
        v-model="form.postcode"
        :validation="{
          rules: 'required|numeric|length:4',
          name: 'Billing Postcode',
        }"
        :disabled="disabled"
        :required="true"
        :error="backendErrors['postcode']"
        :label="`Billing ${translate('address.postcode')}`"
        name="postcode"
        maxlength="4"
        :mask="{ mask: '0000' }"
      />

      <base-input
        v-if="fieldRequirements('abn').enabled"
        v-model="form.abn"
        :validation="{
          rules: `required|numeric|length:${businessNumberMask.unmaskedLength}|businessNumber`,
          name: 'ABN',
          vid: 'abn',
        }"
        :disabled="disabled || businessNumberPending"
        :required="true"
        :error="backendErrors['abn']"
        :label="translate('payment.businessNumber')"
        name="abn"
        :maxlength="businessNumberMask.length"
        :mask="{ mask: businessNumberMask.mask }"
        @input="form.abn = removeWhiteSpace($event)"
      />
    </div>
    <base-metafield
      v-if="userMetadata"
      v-model="form.userMetadata"
      v-analytics:field-value-changed.debounce.custom="
        (e) => ({
          pageGroup: 'Auth',
          page: 'Register',
          component1: e?.field?.label,
          label: generateAnalyticsLabel(e),
        })
      "
      :schema="userMetadataSchema"
      :backend-errors="backendErrors"
      name-prefix="userMetadata"
      class="sm:col-span-2"
      name="userMetadata"
    />
    <div class="mb-6 mt-2 flex items-center">
      <validation-provider
        v-if="fieldRequirements('terms').enabled"
        rules="required:true"
        mode="aggressive"
        slim
      >
        <base-checkbox
          v-model="form.terms"
          v-analytics:change="
            (e) => ({
              pageGroup: 'Auth',
              page: 'Register',
              label: `${e.target.checked ? 'Tick' : 'Untick'} agree with T&C`,
            })
          "
          :disabled="disabled"
          name="agreement"
          look="v4"
        />
      </validation-provider>
      <template v-if="fieldRequirements('terms').fieldname">
        {{ fieldRequirements('terms').fieldname }}
      </template>
      <span v-else class="inline-block text-left">
        I agree with
        <base-link
          v-analytics:click="{
            pageGroup: 'Auth',
            page: 'Register',
            cta: 'Terms & Conditions',
          }"
          href=""
          :ignore-local-warning="true"
          @click.prevent="modal.show('terms-conditions')"
        >
          Terms {{ translate('common.and') }} Conditions
        </base-link>
        and
        <base-link
          v-analytics:click="{
            pageGroup: 'Auth',
            page: 'Register',
            cta: 'Privacy Policy',
          }"
          href=""
          :ignore-local-warning="true"
          @click.prevent="modal.show('privacy-policy')"
        >
          Privacy Policy
        </base-link>
      </span>
    </div>
    <base-button
      :disabled="submitDisabled || invalid"
      :loading="processing"
      class="mx-auto mb-5 w-full"
      data-cy="submit"
      type="submit"
    >
      {{ isSymbioneliteProvider ? 'Next' : 'Register' }}
    </base-button>
    <div class="text-center">
      <base-back
        v-analytics:click="{
          pageGroup: 'Auth',
          page: 'Register',
          cta: 'Back to Login',
        }"
        :to="{ name: 'auth-login' }"
      >
        Back to Login
      </base-back>
    </div>
  </validation-observer>
</template>
